import React from 'react';
//import { connect } from 'react-redux';
//import { selectActiveWord } from '../store/palabra/palabraReducer';

const Reloj = ( props ) => {
  console.log("props");
  console.log(props);
  
  return (
    <div>Reloj:{"hora"}</div>
  );
};

export default (Reloj);
