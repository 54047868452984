import React from 'react'
import Nav from '../components/Common/Header'

import { Outlet } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    console.log('render Main')

    return (
        <div>
            <Nav />
            
            <Outlet />
        </div>
    )
}