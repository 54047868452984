import React from 'react';
import '../css/dashboard.scss'


import {  Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';
import Reloj from '../components/Reloj';

const Home =()=>{
    return(
        <div className="App">
            <img src={logo} alt="" width={100} />
            <h1>Home</h1>
            <Reloj />
            <div>
                <ul>
                    <li>
                        <Link to="/">Home.</Link>
                    </li>
                    <li>
                        <Link to="/list_crud">Crud</Link>
                    </li>
                    <li>
                        <Link to="/crud_modal">Crud modal</Link>
                    </li>
                    <li>
                        <Link to="/crud_ok">Crud ok</Link>
                    </li>
                    
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/pokemons">Pokemons</Link>
                    </li>
                    <li>
                        <Link to="login"> Login</Link>
                    </li>
                </ul>

            </div>
        </div>
    );
}

export default Home;