import React from 'react'
import { BrowserRouter, Routes ,Route } from 'react-router-dom'

import Main from '../_layout/Main'
import MainAdmin from '../_layout/MainAdmin'
import LayoutLila from '../_layout/LayoutLila'

// views
import About from '../views/About'

import Login from '../views/login/Login'
import Signup from '../views/login/Signup'

// Main Admin Views

import Admin from '../views/Admin'
import Dashboard from '../views/Dashboard'
import Ticket from '../views/Ticket'

import Certificado1 from '../views/Certificado1'
import CertificadoList from '../views/CertificadoList'


// admin Views
import Home from '../views/Home'

import Setting from '../views/Setting'
import List_crud from '../views/List_crud'
import CrudModal from '../views/CrudModal'
import Crud_ok from '../views/Crud_ok'



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  /*<Route path="/items/:itemId" element={<ItemsDetail />} />
  <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 />} /> 
  <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 {...props} />} /> */
  return (
    <>
      <Routes>
        <Route path='/login' element={ <Login />} />
        <Route path='/signup' element={ <Signup />} />
    
        <Route element={<MainAdmin />}>
          <Route path='/admin'  element={<Admin />} />    
          <Route path='/admin/setting' element={ <Setting />} />
          
        </Route>

        <Route element={<LayoutLila />}>
        <Route path='/' exact element={ <Dashboard/>} />
          <Route path='/dashboard' exact element={ <Dashboard />}  />  
          <Route path='/ticket' exact element={ <Ticket />} />  
          
          <Route path='/certificado1/:id/:ope' exact element={ <Certificado1 />} /> 
          <Route path='/certificado_list' exact element={ <CertificadoList />} /> 
          
          
        </Route>
    
        <Route element={<Main />}>
          
          <Route path='/home' exact element={ <Home/>} />  
          <Route path='/about' element={ <About />} />
          <Route path='/list_crud' element={ <List_crud />} />
          <Route path='/crud_modal' element={ <CrudModal />} />
          <Route path='/crud_ok' element={ <Crud_ok />} />
        </Route>

        <Route path="*" element={<NoMatch />} /> 
      </Routes>

      

    </>
  )

};
const NoMatch = () => {
  return (<p>There's nothing here: 404!</p>);
};
