import React from 'react';
import '../css/dashboard.scss'


import {  Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';

const Admin =()=>{
    return(
        <div className="App">
            <img src={logo} alt="" />
            <h1>hola Admin</h1>
          
        </div>
    );
}

export default Admin;