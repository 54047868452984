import React, { useRef, useEffect, useState } from "react";

import { Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';


import InputBox from "../components/InputBox";
import { Outlet } from "react-router-dom";
import logo0 from "../assets/img/logo0.png";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const [modalShow, setModalShow] = useState(false);
    const [input, setInput] = useState("");

    const [modalShow1, setModalShow1] = useState(false);
    const [input1, setInput1] = useState("");

    const [popupShow, setPopupShow] = useState(""); /* */
    const [clasePopupShow, setClasePopupShow] = useState("collapse"); /* */

    const navigate = useNavigate();
    
    //--------------para el timer---
    const [expirationDate, setExpirationDate] = useState("");
    let timer = useRef(null);
    

    //---trabajo en el fondo
    useEffect (() => {
        //---- 60 seg * 60= 3600 segundos
        const expirationD = new Date(AuthService.getExpirationDate());
        console.log(expirationD);
        //----control de segundos antes de que se llegue al limite
        //const REFRESH_TOKEN_BUFFER = 1000*10;  //1000= 1seg   1000*10= 10 segundos
        const REFRESH_TOKEN_BUFFER = 1000*60*5;  //5 minutos antes
        const timeToRefreshToken = expirationD - Date.now() - REFRESH_TOKEN_BUFFER;
        console.log(timeToRefreshToken);
        
        if (timeToRefreshToken <= 0) {
            // server call to update app state with new token and new expirationDate
            refreshToken();
        } else {
            /*timer.current = setTimeout(() => {
                console.log("timer limit");
                refreshToken();
                timer.current = null;
            }, timeToRefreshToken); //se configura para que se ejectute en ese tiempo
            */
           const timeId = setInterval(()=>{
            console.log("timer limit");
            refreshToken();
            timer.current = null;
           },timeToRefreshToken);

           return ()=> clearInterval(timeId);
        }
    
        // clear the timeout and the timer ref on unmount
        //return ()=> clearTimeout(timer);
        
    }, [expirationDate]);
    //---------------------------------------------
    const refreshToken =async()=>{
        try {
            await AuthService.refreshToken().then(
              (response) => {
                
                if(response!=""){
                    setExpirationDate({"expirationDate": response});
                  
                }else{
                 //--correcto el registro  
                 navigate("/login");
                }
                //window.location.reload();
              },
              (error) => {
                console.log(error);
              }
            );
          } catch (err) {
            console.log(err);
        } 
    }
    
    //-----------------------------------
    const logout=(e)=>{
        e.preventDefault();
        //console.log("aqui estoy");
        AuthService.logout();
        navigate("/login");
        //let history = useHistory();
        //history.push("/login");
    }
    //---------- NUEVO ------------------
    //----------------INPUTBOX EDITAR (1) ---------------------
    const editar=(e)=>{
        e.preventDefault();
        setModalShow1(true);
    }
    const handleOK1=()=>{ 
        setModalShow1(false);  
        
        let fileURL = "/certificado1/"+ input1+"/e";
        //navigate(fileURL);
        window.location= fileURL;
    }
    const handleCancel1=()=>{ setModalShow1(false);   }
    const handleChange1=(e)=>{setInput1(e.target.value);  }
    //------------------INPUTBOX--REIMPRIME () ------------------------
    const reimprimir=(e)=>{
        e.preventDefault();
        setModalShow(true);
    }
    
    const handleOK=()=>{ 
        setModalShow(false);   
        let fileURL = process.env.REACT_APP_API_URL + "certificado_reciboPDF/"+ input;
        let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
        window.open(fileURL,'',windowFeatures);
    }
    const handleCancel=()=>{ setModalShow(false);   }
    const handleChange=(e)=>{setInput(e.target.value);  }
    //-------------este controla el toofle menu popup
    const menuToggle =()=>{
      if(popupShow===""){
        setPopupShow("nav-open");
        setClasePopupShow("in");
      }else{
        setPopupShow("");
        setClasePopupShow("collapse");
      }
      
    }
    //-----------------------------------------------------------
    return (
       
        <div className={ popupShow }>
          {/* este open se quita y se coloca nav-open */} 
            <header style={{position:"fixed !important"}}>
                <div className="minav wrapper" >
                    {/*<!-- Brand Image -->*/}
                    <div className='navbar-header'>
                        <a href="/" className="pull-left" style={{padding:'0px 10px'}}>
                            <img src={logo0} className='logolila' alt=''/>
                        </a>
                        <a className="navtexto" href="/">NIDERA</a>
                        
                        <button className="navbar-toggle" 
                          onClick={menuToggle}
                          >
                          <span className="sr-only">Toggle navigation</span>
                          <span className="icon-bar">&nbsp;</span>
                          <span className="icon-bar">&nbsp;</span>
                          <span className="icon-bar">&nbsp;</span>
                        </button>

                    </div>
                    {/* menu popup derecho in, collapse*/}
                    <div className= {`navbar-collapse ${ clasePopupShow} `}
                    style={{height: "auto"}}>

                      <ul className="nav navbar-nav">
                        <li>
                          <h3 class="menup-heading">Menu opciones
                            <button type="button" class="btn_close" onClick={(e)=> menuToggle(e) }>
                              x
                            </button>
                          </h3>
                        </li>
                        <li>
                          <Link to="/certificado_list"><i className="fa fa-table"></i> Lista </Link>
                        </li>
                       
                        <li>
                          <Link to="/certificado1/0/n">
                            <i className="fa fa-file-text-o"></i> Nuevo
                          </Link>
                        </li>
                        <li>
                          <a href="/#" onClick={(e)=> editar(e) }>
                              <i className="fa fa-pencil" ></i> Editar
                          </a>
                        </li>
                        <li>
                          <a href="/#" onClick={(e)=> reimprimir(e) }>
                              <i className="fa fa-print" ></i> Reimprimir</a>
                        </li>
                        
                        
                        <li><a href="/#"onClick={(e)=> logout(e)}><i className="fas fa-phone-alt"></i> Cerrar sesión</a></li>

                      </ul>

                      {/*<!-- -->*/}
                      <ul className="nav navbar-nav navbar-right">

                        <li className="nav-item dropdown">
                          <a  href="/" className="nav-link dropdown-toggle" id="navbarDarkDropdownMenuLink" 
                              role="button" data-bs-toggle="dropdown" 
                              aria-expanded="false">
                            <i className="fa fa-menu"></i>Mas opciones..
                          </a>
                          <ul className="dropdown-menu dropdown-with-icons"
                              aria-labelledby="navbarDarkDropdownMenuLink">
                            <li>
                              <a href="https://titanwolf.org/faq" className="" ><span style={{fontSize:"unset"}}>FAQ</span></a>
                            </li>
                            <li>
                              <a href="https://titanwolf.org/about" className="" ><span style={{fontSize:"unset"}}>About </span></a>
                            </li>
                            <li>
                              <a href="https://titanwolf.org/contact" className="" ><span style={{fontSize:"unset"}}>Contact</span></a>
                            </li>
                            <li>
                              <a href="https://titanwolf.org/policies" className="" ><span style={{fontSize:"unset"}}>Policies</span></a>
                            </li>
                            <li>
                              <a href="https://titanwolf.org/ads" className="" ><span style={{fontSize:"unset"}}>Advertise</span></a>
                            </li>
                            <li>
                              <a href="https://titanwolf.org/tos" className="" ><span style={{fontSize:"unset"}}>Terms of service</span></a>
                            </li>
                          </ul>
                        </li>

                        <li><a href="https://titanwolf.org/monetize" style={{ padding:"0px"}} 
                                data-toggle="tooltip"  title="" data-placement="bottom" 
                                data-original-title="monetize">
                              <img src="Please%20verify%20your%20email%20before%20entering_files/coin.png" 
                              alt=""
                              style={{width:"40px", height:"40px" , marginTop:"5px"}}/>
                                </a>
                        </li>

                      </ul>

                    </div>

                    {/*<!-- Navbar -->*/}
                    <nav className="nav-menus">
                        <li>
                          <Link to="/certificado_list"><i className="fa fa-table"></i> Lista </Link>
                        </li>
                        
                        <li className="sub-menus"><a href="/#"><i className="fa fa-list-ul"></i> Certificado</a>
                            <ul>
                                
                                {/*<li><Link to="/certificado1/0/n"><i className="fa fa-pencil"></i> Nuevo</Link></li>-->*/}
                                <li><a href="/certificado1/0/n" >
                                    <i className="fa fa-file-text-o" ></i> Nuevo</a>
                                </li>
                                
                                <li><a href="/#" onClick={(e)=> editar(e) }>
                                    <i className="fa fa-pencil" ></i> Editar</a>
                                </li>
                                <li><a href="/#" onClick={(e)=> reimprimir(e) }>
                                    <i className="fa fa-print" ></i> Reimprimir</a>
                                </li>
                                <li><Link to="/certificado_list"><i className="fa fa-list"></i> Lista certificados</Link></li>
                                
                            </ul>
                        </li>

                        
                        <li><a href="/#"onClick={(e)=> logout(e)}><i className="fas fa-phone-alt"></i> Cerrar sesión</a></li>
                    </nav>
                </div>
            </header>

            <InputBox modalShow={modalShow} handleChange={(e)=>handleChange(e)} 
                    titulo="Entrada" mensaje="Introduzca número de certificado" 
                    handleOK={handleOK} handleCancel={handleCancel} />

            <InputBox modalShow={modalShow1} handleChange={(e)=>handleChange1(e)} 
                    titulo="Entrada" mensaje="Número de certificado" 
                    handleOK={handleOK1} handleCancel={handleCancel1} />
           
            <Outlet />
            
        </div>
    )
}