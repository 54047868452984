
import React, { Component } from 'react';
import axios2 from '../services/axios2';
 
import Chart from "react-apexcharts";
import NuProgressBar from "components/nubox/NuProgressBar";
import CircleS from "components/CircleState/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default class Dashboard extends Component
{
  state={
    options:{
      chart:{
        id:'apexchart-example'
      },
      xaxis:{
        categories: ['Enero','Fefrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
      },
      fill:{
        colors: ['#f44336', '#E91E63','#9C27B0']
      },
      plotOptions:{
        bar:{
          horizontal:false,
          dataLabels:{
            position: 'top', // top, center, bottom
          }
        }
      },
      title: {
        text: 'Historico de ventas'
      },
      /*grid:{
        row:{
          colors:['#F44336', '#E91E63','#9C27B0']
        },
        column:{
          colors: ['#F44336', '#E91E63', '#9C27B0']
        }
      },*/
      legend:{
        labels:{
          colors: '#E91E63',
          useSeriesColors: false,
        },
        markers:{
          width:12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius:12,
          customHtml: undefined,
          onclick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    series:[
      {
        name: 'Team A',
        data: [30,90,35,50,49,60,70,91,125],
      }
      
    ],
    progress: 51,
    progressColor: '#d92550',
    bgColor: '#ebeaef',
    textColor: '#d92550',
    size: '210',
    lineWidth: `30`,
    animated: true,
    roundedStroke: true,
    responsive: false,
    defaultMode: false,
  }

   
  

  //--state
  async componentDidMount(){
    this.cargar_datos();
  } 
  //-----------------------------------------------------
  cargar_datos=()=>{
    console.log("cargar_datos");
    let param={};
    axios2.get("dashboard_get_mes", param)
    //axios2.get("dashboard_get_mes", param)
    .then(response=>{
      console.log("Response", response.data);
      let arr_mes = response.data.arr_mes;
      let arr_canti = response.data.arr_canti;
      //mes, arr_dia  , arr_canti
      this.setState({
        options:{
          xaxis:{
            chart:{
              id: 'apexchart-example',
            },
            categories: arr_mes,
          },
        },
        series:[
          {
            name: 'Muestras',
            data: arr_canti,
          }
        ]
      })
    })
  }
  //-----------------------------------------------------
  render(){
    return(
      <div className="col-md-12 col-xs-12 " style={{ marginTop: "70px", minHeight: "700px" }}>


        <h1>Dashboard de Ventas</h1>

        <div className='container_main'>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary card">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    <div className="widget-title opacity-5 text-uppercase">New accounts</div>
                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                      <div className="widget-chart-flex align-items-center">
                        <div>
                          <span className="opacity-10 text-success pr-2">
                            <i className="fa fa-angle-up"></i>
                          </span>
                          &nbsp;
                          234
                          &nbsp;
                          <small className="opacity-5 pl-1">%</small>
                        </div>
                        <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                          <div className="circle-progress circle-progress-gradient-alt-sm d-inline-block">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-danger border-danger card">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    <div className="widget-title opacity-5 text-uppercase">Total Expenses</div>
                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                      <div className="widget-chart-flex align-items-center">
                        <div>
                          <span className="opacity-10 text-danger pr-2">
                            <i className="fa fa-angle-down"></i>
                          </span>
                          &nbsp;
                          71
                          &nbsp;
                          <small className="opacity-5 pl-1">%</small>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
            
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning border-warning card">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    <div className="widget-title opacity-5 text-uppercase">Company Value</div>
                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                      <div className="widget-chart-flex align-items-center">
                        <div>
                          <small className="opacity-5 pr-1">$</small>
                          &nbsp;
                          1,45M
                          &nbsp;
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-success border-success card">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content">
                    <div className="widget-title opacity-5 text-uppercase">New Employees</div>
                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                      <div className="widget-chart-flex align-items-center">
                        <div>
                          <small className="text-success pr-1">+</small>
                          &nbsp;
                          34
                          &nbsp;
                          <small className="opacity-5 pl-1">hires</small>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
          <div className='row'>
            <div className='col-sm-12 col-md-7 col-lg-8'>
              <div className=' card mb-3'>
                <div className='card-header'>
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    Historico de ventas
                  </div>
                </div>

                <div className='pt-0 card-body'>
                  {/*
                  <Chart
                    options={this.state.options}
                    type="bar"
                    series={this.state.series}
                    width="100%"
                  />
                   */}
                </div>
              </div>
            </div>

            <div className='col-sm-12 col-md-5 col-lg-4'>
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    Income
                  </div>
                </div>
                <div className=' card-body'>

                <CircleS
                  responsive={ this.state.responsive}
                  animate={ this.state.animated}
                  roundedStroke={ this.state.roundedStroke}
                  size={ this.state.size}
                  progress={ this.state.progress}
                  progressColor={ this.state.progressColor}
                  bgColor={ this.state.bgColor}
                  textColor={ this.state.textColor}
                  lineWidth={ this.state.lineWidth}
                  textStyle={{ font: 'bold 5rem Helvetica, Arial, sans-serif' }}
                  onAnimationEnd={() => { console.log('onAnimationEnd'); }}
                />
                <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pr-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-warning">32%</div>
                      </div>
                      <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="32" 
                                aria-valuemin="0" aria-valuemax="100" 
                                style={{ width: "32%"  }}>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Spendings Target</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          


        </div>

        <div className='flex-row'>
          <div className='flex-large'>


            <div>
              
            </div>


          </div>
          <div className='flex-large'>

          </div>
        </div>
      </div>
    );
  }
}
