import React from 'react';
import '../css/dashboard.scss'

import {Container, Row, Col} from 'reactstrap'


import {  Link } from "react-router-dom";
//import '../App.css';
import logo from '../assets/logo.png';

const Ticket =()=>{
    return(
        <Container>
            <Row>
                <Col xs="4" className='bg-primary'>columna 3</Col>
                <Col xs="4" className='bg-warning'>columna 2</Col>
                <Col xs="4" className='bg-danger'>columna 3</Col>
            </Row>
            <img src={logo} alt="" width={100}/>
            <h1>hola Ticket</h1>
           
        </Container>
    );
}

export default Ticket;