import React from 'react';
import { Component, createRef } from 'react';
import axios from "axios";
import { text } from '@fortawesome/fontawesome-svg-core';

class NuAlert2 extends React.Component {

  
  constructor(props) {
    super(props);
    this.state={
      type:'',
      text:'',
      visible: false,
    }
    this.yourRef = createRef();
  }
  //Se invoca inmediatamente después de que un componente se monte (se inserte en el árbol)
  componentDidMount() {
    this.timer = setTimeout(
      this.props.onClose,
      this.props.timeout
    );

    
  }
  
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  
  alertClass (type) {
    let classes = {
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info',
      success: 'alert-success'
    };
    return classes[type] || classes.success;
  }

  show=(obj)=> {
    console.log("show->");
    console.log(obj);
    this.setState({type:obj.type, text:obj.text, visible: true});
  }

  hide=()=> {
    console.log("show->");
    this.setState({visible: false});
  }

  render() {
    
    //console.log("this.state->");
    //console.log(this.state);
    //let display = this.props.show ==true? 'block':'none';
    const alertClassName = `alert ${ this.alertClass(this.state.type) } alert-dismissible fade show`;
 
    return(
      <div className={ alertClassName } style={{display: this.state.visible ? 'block':'none' }} role="alert" >
        
        { this.state.text }

        {/*this.state.show && <div>Hi there</div>*/}

        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" 
        onClick={()=>{ this.hide()} }></button>

        {/*<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" 
        onClick={ this.props.onClose }></button>*/}
        
      </div>
    );
  }
}

/*NuAlert.propTypes = {
  onClose: React.PropTypes.func,
  timeout: React.PropTypes.number,
  message: React.PropTypes.object.isRequired
};*/

NuAlert2.defaultProps = {
  timeout: 3000
};

export default NuAlert2;