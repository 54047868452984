import React, { useState, useEffect } from 'react';  
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

const micontainer={
  flexDirection: "row",
  boxSizing: "border-box",
  display: "flex",
  placeContent: "center",
  alignItems: "center",
}

const NuDateInput =(props)=>{
  const [dateStr, setDateStr] = useState("");
  //---------------------------------------
  useEffect(() => {
    // recalculamos el formato de fecha
    //let d = new Date(2010, 7, 5);
    //console.log("------------")
    //console.log(props.date);//2022-12-16
    
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0
    
    //var spliter = props.date.split('-'); //YYYY-MM-DD
    //const d = new Date(2021, 0, 30);
    //const d = new Date( spliter[0], spliter[1]-1, spliter[2]);
    const d  = strTodate(props.date);
    //let d = Date.parse(props.date +"T04:00:00Z");
    let ye = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('es', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(d); //
    let weekday = new Intl.DateTimeFormat('es', { weekday: 'long' }).format(d); //lunes
    let month = new Intl.DateTimeFormat('es', { month: 'long' }).format(d); //

    //console.log(`${da}-${mo}-${ye}`);
    /* martes 13 diciembre 2022 */
    let cad = `${weekday} ${da} ${month} ${ye} `;
    setDateStr(cad); 

  }, [props.date]);

  //------------------------------------------
  /*const setDate = (newDate) => {
    const date = newDate || new Date();
    this.setState({
      selectedDate:
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    });
    setSelectedDate(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());
  };*/

  //------------------------------------------
  const strTodate =(dateStr)=>{
    //console.log("dateStr:" + dateStr +".")
    if(dateStr===""){
      const d1 = new Date();
      return d1;
    }
    var spliter = dateStr.split('-'); //YYYY-MM-DD
    //const d = new Date(2021, 0, 30);
    const d = new Date( spliter[0], spliter[1]-1, spliter[2]);
    return d;
  }
  //---convierte en formato Mysql YYY-MM-DD
  function yyyymmdd(dateIn) {
    var year  = dateIn.getFullYear();
    // add +1 to month because getMonth() returns month from 0 to 11
    var month  = dateIn.getMonth() + 1;
    var day = dateIn.getDate();
    // show date and month in two digits
    // if month is less than 10, add a 0 before it
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    // use the separator to join them
    return year + "-" + month + "-" + day;
  }
  // var today = new Date();
  // console.log(yyyymmdd(today))
  // ------------------------------------------
  const getPreviousDate = () => {
    //const { selectedDate } = this.state
    const selectedDate = strTodate(props.date);

    const currentDayInMilli = new Date(selectedDate).getTime()
    const oneDay = 1000 * 60 *60 *24
    const previousDayInMilli = currentDayInMilli - oneDay
    const previousDate = new Date(previousDayInMilli)

    //this.setDate(previousDate)
    props.onDateChange(yyyymmdd(previousDate)); //YYY-mm-dd mysql

  }

  // ------------------------------------------
  const getNextDate = () => {
    //const { selectedDate } = this.state
    const selectedDate = strTodate(props.date);

    const currentDayInMilli = new Date(selectedDate).getTime()
    const oneDay = 1000 * 60 *60 *24
    const nextDayInMilli = currentDayInMilli + oneDay
    const nextDate = new Date(nextDayInMilli)

    //this.setDate(nextDate)
    props.onDateChange(yyyymmdd(nextDate)); //YYY-mm-dd mysql

  }
  
  return(
    <div style={micontainer}>
      <Button className="btn btn-primary  btn-fab btn-round btn-fab-mini" onClick={ getPreviousDate}>
        <i className="fa fa-chevron-left"></i>
      </Button>
      <div fxflex="40" 
        fxlayoutalign="center center" 
        className="title" style={{height: "100%", width: "100%", marginRight: "10px", placeContent: "center", alignItems: "center", flexDirection: "row", boxSizing: "border-box", display: "flex", flex: "1 1 100%", maxWidth: "300px"}}
        >
          {/* martes 13 diciembre 2022 */}
        <h4 style={{color: "#336699" }} >{dateStr}</h4>
      </div>
      <Button className="btn btn-primary  btn-fab btn-round btn-fab-mini" onClick={ getNextDate}>
        <i className="fa fa-chevron-right"></i>
      </Button>
    

    </div>
  )
}

export default NuDateInput

