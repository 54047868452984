import React from "react";
import axios from 'axios';
import axios2 from '../services/axios2';

import {useParams} from "react-router-dom";
import {Link,hashHistory} from 'react-router-dom';

import NuDateInput from "../components/NuDateInput";

import listac from '../assets/img/lista2.png';


import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
  Table,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

//type:'numeric'
const columnas=[
    {  field:'id', title: 'Cod. Cert.', type:'numeric'},
    {  field:'cliente', title: 'Cliente'},
    {  field:'nombre', title: 'Nombre'},
    {  field:'lote', title: 'Lote'},
    {  field:'elem', title: 'Elementos'},
]

const data = [
  { id_certi: 1, id_cliente:0, nombre_cli: "Naruto", nombre: "AMSG", fecha_cert:'V-415'},
  { id_certi: 2, id_cliente:0, nombre_cli: "Goku", nombre: "EMIa", fecha_cert:'V-415'},
 
];



class CertificadoList extends React.Component {
  state = {
    data: data,
    fechai:'',
    modalActualizar: false,
    modalInsertar: false,
    form: {
      id: "",
      personaje: "",
      anime: "",
    },
  };
  
  componentDidMount() {
    //--limpiamos el array de prueba
    this.setState({data:[]});

    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');
  
    this.setState({fechai: defaultValue}); 

    this.fetchData(this.state.fechai);
  }

  //--cambiamos la fecha
  handleChangeFechai=(event)=> {    
    this.setState({fechai: event.target.value}); 
    //------------- recargamos la lista --------
    this.fetchData(this.state.fechai);
  }

  //----cargamos los datos desde la base de datos
  fetchData = (fechai) => {
    if(fechai==="") {return;}

    let param = { 
      fechai: fechai,
    }
    console.log(param);

    axios2.post(process.env.REACT_APP_API_URL  + "certificado_lista_x_fecha", param).then(res => {
        if(res.data.length>0){ 
            NotificationManager.success("Correcto");
            this.setState({data: res.data});
        }else{
          this.setState({data: []});
            //NotificationManager.error("No existen registros");
        }

    }).catch(error => {
        //if(error.response.status && error.response.status===400)
        NotificationManager.error("Bad Request");
        //else NotificationManager.error("Something Went Wrong");
        //this.setState({ errors: error })
        console.log(error);
        
    });
  };
 
 
  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  //---clic en el boton para buscar los elementos
  search_date=(e)=>{
    //console.log(this.state.fechai);
    if(this.state.fechai!==""){
      this.fetchData(this.state.fechai);
    }
    
  }
  
  imprimir =(id_certi)=>{
    let fileURL = process.env.REACT_APP_API_URL  + "certificado_reciboPDF/"+ id_certi;
    let  windowFeatures = 'width=650,height= 700, left=100,top=100 ';
    window.open(fileURL,'',windowFeatures);
  }
  //---------del control datainput
  dateChange =(dateYmd) =>{
    console.log("dateChange:" + dateYmd);
    this.setState({ 
      "fechai": dateYmd,   },    
    );
    //------------- recargamos la lista --------
    this.fetchData(dateYmd);
  }
  //-------------------------------------
  render() {
    
    return (
      <>
        <Container style={{marginTop:"70px",minHeight:"700px"}}>
        
        <NotificationContainer/>
        <br />

        <div class="row">
					<div class="col-sm-6">
						Fecha:
            <input type="date" value={this.state.fechai} onChange={this.handleChangeFechai} />
            <button className="btn btn-default "  onClick={this.search_date}>
              <i className="fa fa-search"></i>
            </button>      
					</div>
          
					<div class="col-sm-6">
            <Link to = "/certificado1/0/n" className="btn btn-success" style={{float: "right"}}>Nuevo </Link>		
					</div>
				</div>       

             
        <NuDateInput 
          className="btn btn-default"
          date = {this.state.fechai}
          onDateChange={this.dateChange}
          >
        </NuDateInput> 


          <div className="" id="holder">

    <div className="card">

      <div className="card-body" id="feeds_47fd9ca3-e9d2-4807-a42e-668173bfaf32">
        <div className="row">
          <div className="col-md-2 col-xs-3">
            <a href="/profile/Brittyn" target="_blank">
              <img src={listac} alt="..." width={40}
                className="img img-circle profileicon"/>

            </a>
          </div>
          <div className="col-md-8 col-xs-7" style={{ display:"inline-block" }}>
            <h5>
              Certificados
            </h5>
         

          </div>
          <div className="col-md-2 col-xs-2 text-right" style={{ cursor:"pointer" }}>
            <span className=""> <i className="fa fa-print">Imprimir</i> </span>
          </div>

        </div>
        <hr/>
        
        <div className="postbox">
          
        <Table>
            <thead>
              <tr>
                <th>Nro.Cert.</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Nombre</th>
                <th>Mineral</th>
                <th>Acción</th>
              </tr>
            </thead>

            <tbody>
              {this.state.data.map((dato) => (
                <tr key={dato.id_certi}>
                  <td>{dato.id_certi}</td>
                  <td>{dato.fecha_cert}</td>
                  <td>{dato.nombre_cli}</td>
                  <td>{dato.nombre}</td>
                  <td>{dato.mineral}</td>
                  <td>
                    <Link className="btn btn-primary" to = {`/certificado1/${dato.id_certi}/e`}> Editar </Link>

                    <Button color="info" onClick={()=> this.imprimir(dato.id_certi)}>Imprimir</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <hr />
        </div>
        
      </div>
    </div>
  </div>

                      

        
        </Container>

     
      </>
    );
  }
}
  
  //------------para que soporte la funcionalidad de parametros
export function withRouter(Children){
  return(props)=>{

     const match  = {params: useParams()};
     return <Children {...props}  match = {match}/>
 }
}
export default withRouter(CertificadoList);